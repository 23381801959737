import { getLastUpdatedTime } from "src/components/Homes/DeviceDetails/deviceLastUpdatedUtil"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { TClockTypeMaybe } from "src/data/user/user"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"

export function DeviceLastUpdated({
  online,
  lastHeardFromAt,
  timezone,
}: {
  online: boolean
  lastHeardFromAt?: string
  timezone: string
}) {
  const { t } = useTranslate()
  const clockType = useGetUser().clock_type

  if (!lastHeardFromAt) {
    return null
  }

  const statusText = translateTimeDurationStatus({
    online,
    clockType,
    lastHeardFromAt,
    timezone,
    t,
  })

  if (online) {
    return <div>{statusText}</div>
  }

  return (
    <MBanner type="error" size="small">
      {statusText}
    </MBanner>
  )
}

function translateTimeDurationStatus({
  online,
  clockType,
  lastHeardFromAt,
  timezone,
  t,
}: {
  online: boolean
  clockType: TClockTypeMaybe
  lastHeardFromAt: string
  timezone: string
  t: ITranslateFunction
}) {
  const { langKey, interpolationValue } = getLastUpdatedTime({
    clockType,
    lastHeardFromAt,
    timezone,
    online,
  })
  if (!langKey) {
    return ""
  }

  return t(langKey, [interpolationValue])
}
